import { FeatureLayout } from '@widgets/layout';
import { FieldBuilder } from '@ui/FieldBuilder';
import { RequestFeedbackForm } from '@features/request-feedback';
import { t } from '@lib/i18n';

import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import WavesDivider from '@ui/WavesDivider';
import { ClientLogos } from '@widgets/client-logos';
import { useForm } from 'react-hook-form';
import { SalesManCard, FormFooter } from './ui';

const tr = (key) => t(`contacts.${key}`);

const FeatureContent = () => (
  <>
    <Typography variant="header1">
      {tr('header')}
    </Typography>
    <Typography variant="subhead1">
      {tr('subheader')}
    </Typography>
  </>
);

const ContactsPage = () => {
  const requestCallForm = useForm({ mode: 'onChange' });

  return (
    <FeatureLayout
      mainLayoutProps={{ seo: t('seo.contacts'), banner: true }}
      featureChildren={<FeatureContent />}
    >
      <MediaQuery lessThan="lg">
        <div>
          <div className="lg:grid-layout !px-0 md:mx-[28px]">
            <div className="flex lg:mt-9 lg-down:flex-col">
              <div className="lg:col-6 sm:pt-4 md:pt-8 lg-down:pb-[21px]">
                <SalesManCard person={tr('sales_man')} variant="sales" className="md:mb-[56px] md-down:mb-[16px]" />
                <SalesManCard person={tr('support_man')} variant="support" />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
      <div className="mx-auto max-w-[1440px] md:mx-[28px] lg:px-[40px]">
        <div className="flex justify-between gap-3 lg:mt-9 lg-down:flex-col">
          <MediaQuery greaterThan="md">
            <div className="flex-none sm:pt-7 md:pt-8 lg:w-[528px] lg-down:pb-[21px]">
              <SalesManCard person={tr('sales_man')} variant="sales" />
              <SalesManCard className="mt-8" person={tr('support_man')} variant="support" />
            </div>
          </MediaQuery>
          <div className="flex-auto sm:mt-7 md:mt-8 lg:max-w-[640px]">
            <RequestFeedbackForm
              markRequiredFields={false}
              submitButtonClassName="w-[146px]"
              form={requestCallForm}
              footer={<FormFooter />}
              extra={(
                <FieldBuilder.GoogleCaptchaV2
                  name="captcha"
                />
              )}
            />
          </div>
        </div>
        <WavesDivider className="sm:my-7 md:my-8 lg:my-10" />
        <Typography variant="header2" as="h2" className="text-center font-semibold">
          {t('main_page.feedback.title')}
        </Typography>
      </div>
      <ClientLogos />
    </FeatureLayout>
  );
};

export default ContactsPage;
